import {
	DiscrepancyStatus,
	statementDiscrepancyTooltip,
	StatementEntry,
	StatementEntryVersion,
	StatementStatus,
} from 'src/app/features/statements/models/statements.models';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { formatCurrency } from '@angular/common';
import { parseDateToLuxon } from 'src/app/shared/utils/date-format';
import { DateTime } from 'luxon';
import { TitleCasePipe } from '@angular/common';

export class StatementMetricsData {
	name: string;
	title: string;
	statement: StatementEntry;
	currencyDict: CurrencyDict;
	dateDisplay: string;
	openingBalance: string;
	closingBalance: string;
	reportedOpeningBalanceSummary: string;
	reportedClosingBalanceSummary: string;
	calculatedClosingBalanceSummary: string;
	balanceDiscrepancy: string;
	discrepancyTooltip: string;
	inflowsAmount: string;
	accountsCount: string;
	openingBalancesCount: string;
	closingBalancesCount: string;
	inflowsCount: string;
	outflowsAmount: string;
	outflowsCount: string;
	customDisplayCadence: string;
	currency: Currency;
	hasFullMetricsData: boolean;
	source: string;
	isProcessing: boolean;

	constructor(statement: StatementEntry, currentStatementVersion: StatementEntryVersion, currencyDict: CurrencyDict) {
		this.currencyDict = currencyDict;
		this.currency = this.currencyDict[statement.currency];
		this.isProcessing = false;
		this.setData(statement, currentStatementVersion);
	}

	setData(statement: StatementEntry, currentStatementVersion: StatementEntryVersion): void {
		this.source = 'statements';
		this.hasFullMetricsData = false;
		this.statement = statement;
		this.name = statement.name;
		this.title = this.name;
		if (statement.recurring) {
			const titlecasePipe: TitleCasePipe = new TitleCasePipe();
			this.customDisplayCadence = titlecasePipe.transform(statement.cadence);
		} else {
			this.customDisplayCadence = 'One-time';
		}
		if (!currentStatementVersion) {
			this.dateDisplay = `${this.getDisplayDate(statement.latestStartDate)} - ${this.getDisplayDate(statement.latestEndDate)}`;
		}
		this.accountsCount = statement?.numberOfAccounts ? new Intl.NumberFormat('en-US').format(statement.numberOfAccounts) : '-';
		if (currentStatementVersion) {
			this.dateDisplay = `${this.getDisplayDate(currentStatementVersion?.cadenceStartDate)} - ${this.getDisplayDate(currentStatementVersion?.cadenceEndDate)}`;
			this.isProcessing = currentStatementVersion.statementStatus === StatementStatus.processing;
			this.openingBalance = this.formatDisplayCurrency(currentStatementVersion.reportedOpeningBalanceConverted);
			this.closingBalance = this.formatDisplayCurrency(currentStatementVersion.reportedClosingBalanceConverted);
			this.reportedOpeningBalanceSummary = this.formatDisplayCurrency(currentStatementVersion.reportedOpeningBalanceConverted, null);
			this.reportedClosingBalanceSummary = this.formatDisplayCurrency(currentStatementVersion.reportedClosingBalanceConverted, null);
			this.calculatedClosingBalanceSummary = this.isProcessing
				? '-'
				: this.formatDisplayCurrency(currentStatementVersion.calculatedClosingBalanceConverted, null);
			this.inflowsAmount = this.formatDisplayCurrency(currentStatementVersion.inflowAmount);
			this.outflowsAmount = this.formatDisplayCurrency(currentStatementVersion.outflowAmount);
			this.accountsCount = currentStatementVersion?.numberOfAccounts ? new Intl.NumberFormat('en-US').format(currentStatementVersion.numberOfAccounts) : '-';
			this.openingBalancesCount = new Intl.NumberFormat('en-US').format(currentStatementVersion.numberOfOpeningBalances);
			this.closingBalancesCount = new Intl.NumberFormat('en-US').format(currentStatementVersion.numberOfClosingBalances);
			this.inflowsCount = new Intl.NumberFormat('en-US').format(currentStatementVersion.numberOfInflowTransactions);
			this.outflowsCount = new Intl.NumberFormat('en-US').format(currentStatementVersion.numberOfOutflowTransactions);
			if (!this.isProcessing && currentStatementVersion.dataStatus !== DiscrepancyStatus.noDiscrepancies) {
				const balanceDiscrepancy: number = currentStatementVersion.reportedClosingBalanceConverted - currentStatementVersion.calculatedClosingBalanceConverted;
				this.balanceDiscrepancy = this.formatDisplayCurrency(balanceDiscrepancy, null);
				this.discrepancyTooltip = statementDiscrepancyTooltip(currentStatementVersion.dataStatus);
			}
			this.hasFullMetricsData = true;
		}
	}

	formatDisplayCurrency(amount: number, digitsInfo: string = '1.0-0'): string {
		const displayAmount: string = formatCurrency(Math.abs(amount), 'en-US', this.currency.symbol, this.statement.currency, digitsInfo);
		if (amount < 0) {
			return `(${displayAmount})`;
		} else {
			return displayAmount;
		}
	}

	getDisplayDate(date: string): string {
		if (!date) {
			return 'N/A';
		}
		const startDate: DateTime = parseDateToLuxon(date);
		return startDate.toLocaleString(DateTime.DATE_SHORT);
	}
}
