import { TQLPayload } from 'src/app/shared/models/tql.model';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { PaginatedTableColumn } from 'src/app/shared/models/paginated-table-view-model';
import { transactionPagColumns } from 'src/app/features/transactions/models/paginated-transactions-table-view-model';
import { cloneDeep } from 'lodash';
import { ClosingBalanceOptionIds, OpeningBalanceOptionIds } from 'src/app/features/statements/models/statement-account-settings.model';
import { Weekdays } from 'src/app/shared/models/weekdays.model';
import { DateTime } from 'luxon';
import { StatementDialogEnum } from 'src/app/features/statements/services/statements-events.service';

export interface StatementMetricsDialogOptions {
	statement: StatementEntry;
	statementVersion: StatementEntryVersion;
	statementPreferences: StatementPreferences;
	type: StatementDialogEnum;
}

export function metricsDialogTitle(dialog: StatementDialogEnum): string {
	switch (dialog) {
		case StatementDialogEnum.openingBalances:
			return 'Opening Balances';
		case StatementDialogEnum.closingBalances:
			return 'Closing Balances';
		case StatementDialogEnum.creditTransactions:
			return 'Inflows';
		case StatementDialogEnum.debitTransactions:
			return 'Outflows';
	}
}

export class StatementCreateResponse {
	statementId: string;
}

export class StatementsResponse {
	total: number;
	statements: StatementEntry[];
}

export class StatementResponse {
	statement: StatementEntry;
}

export class StatementVersionBalancesResponse {
	nextPageKey?: string;
	balanceType: string;
	balances: StatementBalance[];
}

export class StatementVersionTransactionsResponse {
	nextPageKey?: string;
	totalTransactions: number;
	transactions: StatementTransaction[];
}

export class StatementEntry {
	statementId: string;
	balanceTql: TQLPayload;
	transactionTql?: TQLPayload;
	startDate: string;
	endDate: string;
	cadence: Cadence;
	recurring: boolean;
	lastUpdated: string;
	currency: string;
	name: string;
	numberOfAccounts?: number;
	numberOfVersions?: number;
	rounding: string;
	preferences: StatementPreferences;
	includeWeekends: boolean;
	lastDayOfWeek?: string;
	latestStartDate?: string;
	latestEndDate?: string;
	latestVersion?: StatementEntryVersion;
	latestVersionStatus?: string;
	createdBy?: string;
}

export class StatementPreferences {
	columns: StatementColumnConfig[];
	grouping: StatementViewGrouping;
	balancePropertyOverrides?: BalancePropertyOverrides;
	transactionsSameAsBalances?: boolean;
}

export class StatementColumnConfig {
	sortKey: TransactionColumnsSortKeys;
	isVisible: boolean;
	displayName: string;
	displayInfo?: string;
}

export class StatementVersionsResponse {
	statementVersions: StatementEntryVersion[];
}

export class StatementVersionExportResponse {
	url: string;
	completed: boolean;
	message: string;
}

export class StatementEntryVersion {
	statementVersionId: string;
	statementId: string;
	name?: string;
	transactionTql?: TQLPayload;
	balanceTql?: TQLPayload;
	recurring: boolean;
	currency: string;
	cadence?: Cadence;
	cadenceStartDate: string;
	cadenceEndDate: string;
	rounding: string;
	statementStatus: StatementStatus;
	includeWeekends: boolean;
	lastDayOfWeek?: string;
	type: string;
	preferences: StatementPreferences;
	balancePropertyOverrides?: BalancePropertyOverrides;
	dataStatus: DiscrepancyStatus;
	reportedOpeningBalanceConverted?: number;
	reportedClosingBalanceConverted?: number;
	calculatedClosingBalanceConverted?: number;
	inflowAmount?: number;
	outflowAmount?: number;
	numberOfAccounts?: number;
	numberOfOpeningBalances?: number;
	numberOfClosingBalances?: number;
	numberOfInflowTransactions?: number;
	numberOfOutflowTransactions?: number;
	createdBy?: string;
}

export class StatementBalance {
	accountId: string;
	accountNumber: string;
	accountNumberFull: string;
	nickname: string;
	name: string;
	institutionId: string;
	dataProviderId: string;
	isManual: boolean;
	isClosed: boolean;
	entityGroupingId: string;
	regionGroupingId: string;
	divisionGroupingId: string;
	type: string;
	institutionName: string;
	institutionNickname: string;
	currency: string;
	lastRefreshTs: string;
	balanceDate: string;
	balanceEpoch: string;
	balanceTimestamp: string;
	balanceOpeningNative: number;
	balanceOpeningConverted: number;
	balanceClosingNative: number;
	balanceClosingConverted: number;
	currencyOverride: string;
	conversionRateTimestamp: string;
	conversionRate: number;
	entityId: string;
	entityName: string;
	balanceProperty: ClosingBalanceOptionIds | OpeningBalanceOptionIds;
}

export class StatementTransaction {
	accountId: string;
	transactionId: string;
	bankReference: string;
	transactionDate: Date;
	transactionDatetime: DateTime;
	amount?: number;
	amountConverted: number;
	type: string;
	description: string;
	descriptionDetail: string;
	currency: string;
	currencyOverride: string;
	status: string;
	isIntraday: string;
	isManual: boolean;
	runningBalanceConverted: number;
	dataProviderId: string;
	institutionId: string;
	institutionName: string;
	institutionNickname: string;
	accountNumber: string;
	accountName: string;
	accountNickname: string;
	accountNumberFull: string;
	accountType: string;
}

export interface StatementsPagePreferences {
	favorites: string[];
	statementIndividualPreferences: {
		[statementId: string]: StatementPreferences;
	};
}

export enum StatementType {
	automated = 'automated',
	oneTime = 'one-time',
}

export enum StatementViewGrouping {
	default = '',
	currency = 'currency',
	institutionId = 'institutionId',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	accountType = 'accountType',
	inflows = 'inflows',
	outflows = 'outflows',
}

export enum DiscrepancyStatus {
	noDiscrepancies = 'no_discrepancies',
	fxRateFluctuations = 'fx_rate_fluctuations',
	missingOpeningBalances = 'missing_opening_balances',
	missingClosingBalances = 'missing_closing_balances',
	missingOpeningAndClosingBalances = 'missing_opening_and_closing_balances',
	unknown = 'unknown',
}

export function statementDiscrepancyTooltip(discrepancyCode: DiscrepancyStatus): string {
	switch (discrepancyCode) {
		case DiscrepancyStatus.fxRateFluctuations:
			return 'FX rate fluctuations';
		case DiscrepancyStatus.missingOpeningBalances:
			return 'Missing opening balances';
		case DiscrepancyStatus.missingClosingBalances:
			return 'Missing closing Balances';
		case DiscrepancyStatus.missingOpeningAndClosingBalances:
			return 'Missing opening and closing balances';
		case DiscrepancyStatus.unknown:
			return 'Incomplete data'; // to update
	}
}

export enum StatementStatus {
	processing = 'processing',
	complete = 'complete',
	incomplete = 'incomplete',
}

export class StatementGroupType {
	value: StatementViewGrouping;
	view: string;
}

export const statementTransactionGroupTypes: StatementGroupType[] = [
	{ value: StatementViewGrouping.default, view: 'Default' },
	{ value: StatementViewGrouping.currency, view: 'Currency' },
	{ value: StatementViewGrouping.institutionId, view: 'Bank' },
	{ value: StatementViewGrouping.entityId, view: 'Entity Alias' }, // TODO: nest entity
	{ value: StatementViewGrouping.entityRegion, view: 'Entity Region' },
	{ value: StatementViewGrouping.entityDivision, view: 'Entity Division' },
	{ value: StatementViewGrouping.accountType, view: 'Account Type' },
	{ value: StatementViewGrouping.inflows, view: 'Inflows' },
	{ value: StatementViewGrouping.outflows, view: 'Outflows' },
];

export class OpeningClosingBalanceProperties {
	opening?: OpeningBalanceOptionIds;
	closing?: ClosingBalanceOptionIds;
}

export class UpdatedConfigColumns {
	columns: PaginatedTableColumn[];
	updatedColumnsConfig: StatementColumnConfig[];
}

export class BalancePropertyOverrides {
	[accountId: string]: OpeningClosingBalanceProperties;
}

export function statementWeekdayMappingToUI(weekday: string): string {
	switch (weekday) {
		case '0':
			return Weekdays.monday;
		case '1':
			return Weekdays.tuesday;
		case '2':
			return Weekdays.wednesday;
		case '3':
			return Weekdays.thursday;
		case '4':
			return Weekdays.friday;
		case '5':
			return Weekdays.saturday;
		case '6':
			return Weekdays.sunday;
		default:
			return weekday;
	}
}

export enum TransactionColumnsSortKeys {
	date = 'date',
	description = 'description',
	descriptionDetail = 'descriptionDetail',
	institution = 'institutionName',
	bankReference = 'bankReference',
	account = 'accountNumber',
	accountName = 'accountName',
	type = 'type',
	amount = 'amount',
	amountConverted = 'amountConverted',
	currency = 'currency',
	category = 'category',
	memo = 'memo',
	glTag = 'glTag',
	// status = 'status',
	isIntraday = 'isIntraday',
	runningBalanceConverted = 'runningBalanceConverted',
}

export function getTransactionDisplayName(sortKey: TransactionColumnsSortKeys): string {
	switch (sortKey) {
		case TransactionColumnsSortKeys.date:
			return 'Date';
		case TransactionColumnsSortKeys.description:
			return 'Description';
		case TransactionColumnsSortKeys.descriptionDetail:
			return 'Description Details';
		case TransactionColumnsSortKeys.institution:
			return 'Bank';
		case TransactionColumnsSortKeys.bankReference:
			return 'Bank Reference';
		case TransactionColumnsSortKeys.account:
			return 'Account Number';
		case TransactionColumnsSortKeys.accountName:
			return 'Account Name';
		case TransactionColumnsSortKeys.type:
			return 'Credit/Debit';
		case TransactionColumnsSortKeys.amount:
			return 'Amount';
		case TransactionColumnsSortKeys.amountConverted:
			return 'Amount (USD Eq.)';
		case TransactionColumnsSortKeys.currency:
			return 'Currency';
		case TransactionColumnsSortKeys.category:
			return 'Category';
		case TransactionColumnsSortKeys.memo:
			return 'Memo';
		case TransactionColumnsSortKeys.glTag:
			return 'G/L Tag';
		// case TransactionColumnsSortKeys.status:
		// 	return 'Status';
		case TransactionColumnsSortKeys.isIntraday:
			return 'Intraday';
		case TransactionColumnsSortKeys.runningBalanceConverted:
			return 'Balance (USD Eq.)';
	}
}

export class StatementColumnConfigMenu {
	private static defaultStatementColumns: PaginatedTableColumn[] = transactionPagColumns.filter(
		(col: PaginatedTableColumn) => col.colDef in TransactionColumnsSortKeys
	);
	private static defaultConfigColumns: StatementColumnConfig[] = this.defaultStatementColumns
		.map((col: PaginatedTableColumn) => ({
			sortKey: TransactionColumnsSortKeys[col.colDef],
			isVisible: true,
			displayName: null,
		}))
		.filter((col: StatementColumnConfig) => {
			const staticColumns: TransactionColumnsSortKeys[] = [];
			return !staticColumns.includes(col.sortKey);
		});

	static constructMenu(): StatementColumnConfig[] {
		return StatementColumnConfigMenu.defaultConfigColumns.map((col: StatementColumnConfig) => ({ ...col }));
	}

	static constructColumns(columnsConfig: StatementColumnConfig[], dialogContext?: StatementDialogEnum): UpdatedConfigColumns {
		const nonContextColumns: string[] = ['runningBalanceConverted'];
		let columns: PaginatedTableColumn[] = [];
		let newColumns: boolean = false;
		const allColumns: StatementColumnConfig[] = this.constructMenu();
		allColumns.forEach((col: StatementColumnConfig) => {
			const existingColumn: StatementColumnConfig = columnsConfig.find((existingCol: StatementColumnConfig) => existingCol.sortKey === col.sortKey);
			if (!existingColumn) {
				col.isVisible = true;
				columnsConfig.push(col);
				newColumns = true;
			}
		});
		columnsConfig.forEach((columnConfig: StatementColumnConfig) => {
			const tableColumn: PaginatedTableColumn = cloneDeep(
				this.defaultStatementColumns.find((col: PaginatedTableColumn) => columnConfig.sortKey === TransactionColumnsSortKeys[col.colDef])
			);
			tableColumn.included = true;
			// no on demand sorting for statement transactions API
			tableColumn.sortable = false;
			if (columnConfig.isVisible) {
				if (columnConfig?.displayName) {
					tableColumn.header = columnConfig.displayName;
				}
				columns.push(tableColumn);
			}
		});
		if (dialogContext) {
			columns = columns.filter((column: PaginatedTableColumn) => !nonContextColumns.includes(column.colDef));
		}
		return { columns: columns, updatedColumnsConfig: newColumns ? columnsConfig : null };
	}

	static filterColumns(columns: StatementColumnConfig[]): StatementColumnConfig[] {
		const allowedSortKeys: TransactionColumnsSortKeys[] = StatementColumnConfigMenu.defaultConfigColumns.map((col: StatementColumnConfig) => col.sortKey);
		return columns.filter((col: StatementColumnConfig) => allowedSortKeys.includes(col.sortKey));
	}
}
